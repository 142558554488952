// 扩展userInfo 具有记录token有效期的功能
interface ITokenExpiration {
  jwt_token: string;
  readonly tokenExpirationDate: number; // 记录token过期的时间戳

  getTokenExpirationDate: () => number;
}

export interface IRole {
  account: string;
  roleId: number;
  roleName: string;
}

interface IUserInfo {
  id: string;
  account: string;
  cellphone: string;
  nickName: string;
  userName: string;
  type: number;
  isCassUser: number;
  companyId: string;
  companyName: string;
  companyShortName: string;
  roles: IRole[];
}

/**
 * userInfo object has two status: init | authed.
 */
export class UserInfo implements ITokenExpiration {
  id = '';

  account = '';

  cellphone = '';

  nickName = '';

  userName = '';

  type = 0;

  isCassUser = 0;

  companyId = '';

  companyName = '';

  companyShortName = '';

  roles: IRole[] = [];

  isManager = false; // 前端拓展字段，根据roles 列表 是否具有 roleId === ‘1000’ 来判断是否是管理员

  jwt_token = '';

  readonly tokenExpirationDate: number = 0;

  constructor(userInfo?: IUserInfo, jwtToken?: string) {
    if (!userInfo) return;
    this.id = userInfo.id || '';
    this.account = userInfo.account || '';
    this.cellphone = userInfo.cellphone || '';
    this.nickName = userInfo.nickName || '';
    this.userName = userInfo.userName || '';
    this.type = userInfo.type || 0;
    this.isCassUser = userInfo.isCassUser || 0;
    this.companyId = userInfo.companyId || '';
    this.companyName = userInfo.companyName || '';
    this.companyShortName = userInfo.companyShortName || '';
    this.roles = userInfo.roles || [];

    this.isManager = (userInfo.roles || []).some((role: IRole) => role.roleId === 1000);
    this.jwt_token = jwtToken || '';
    this.tokenExpirationDate = this.getTokenExpirationDate();
  }

  getTokenExpirationDate = () => {
    return new Date().getTime() + 12 * 3600 * 1000;
  };
}
