import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';

export const httpInstance: AxiosInstance = axios.create({
  baseURL: '',
  responseType: 'json',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  timeout: 10000,
});

// 在用户登出时，清除token
export const clearHttpCacheToken = () => {};

export default {
  get: (url: string, data: object = {}, config: AxiosRequestConfig = {}) => {
    return httpInstance.get(url, { params: data, ...config }).then((res) => res.data);
  },
  delete: (url: string, data: object | [] = {}, config: AxiosRequestConfig = {}) => {
    return httpInstance.delete(url, { params: data, ...config }).then((res) => res.data);
  },

  post: (url: string, data: object = {}, config: AxiosRequestConfig = {}) => {
    return httpInstance.post(url, data, config).then((res) => res.data);
  },
  put: (url: string, data: object = {}, config: AxiosRequestConfig = {}) => {
    return httpInstance.put(url, data, config).then((res) => res.data);
  },

  getFile: (url: string, data: object = {}, config: AxiosRequestConfig = {}) => {
    return httpInstance({
      method: 'GET',
      url,
      responseType: 'blob',
      params: { ...data, ...config },
    }).then((res) => res);
  },

  postFile: (url: string, data: object = {}, config: AxiosRequestConfig = {}) => {
    return httpInstance({
      method: 'post',
      url,
      responseType: 'blob',
      data: { ...data, ...config },
    }).then((res) => res);
  },
};
