/**
 * 根据新的eslint规则，JSON.parse需要在try catch中使用
 * @param value 要用JSON.parse()进行解析的字符串,JSON.parse的第一个参数
 * @param whenWrongValue 当JSON.parse()出错时的默认返回值,若不传则默认是undefined
 * @param reviver JSON.parse的第二个参数
 */
export const JSONParse = (
  value: string,
  whenWrongValue?: any,
  reviver?: (this: any, key: string, value: any) => any,
) => {
  let result;
  try {
    result = JSON.parse(value, reviver);
  } catch (err) {
    result = whenWrongValue;
  }
  return result;
};

/**
 * 根据新的eslint规则，JSON.stringify需要在try catch中使用
 * @param value  JSON.stringify的第一个参数
 * @param whenWrongValue 当JSON.stringify()出错时的默认返回值,若不传则默认是空字符串“”
 * @param replacer JSON.stringify的第二个参数
 */
export const JSONStringify = (
  value: any,
  whenWrongValue?: string,
  replacer?: (this: any, key: string, value: any) => any,
) => {
  let result = '';
  try {
    result = JSON.stringify(value, replacer);
  } catch (err) {
    result = whenWrongValue || '';
  }
  return result;
};
