import { JSONParse, JSONStringify } from './catch-exception';

export default {
  get: (key: string) => {
    if (!localStorage) {
      return '';
    }
    const value: string | null = localStorage.getItem(key);
    return value ? JSONParse(value) : '';
  },

  set: (key: string, value: any): void => {
    localStorage.setItem(key, JSONStringify(value || ''));
  },

  remove: (key: string): void => {
    localStorage.removeItem(key);
  },

  clear: (): void => {
    localStorage.clear();
  },
};
