/**
 * 支持动态设置公共路径，当前配置会使用 window.__PUBLIC_PATH
 * 作为公共路径，这样我们可以在不同环境使用同一套静态文件，
 * 不同环境只要设置不同的 window.__PUBLIC_PATH 就行
 */
// eslint-disable-next-line
declare var __webpack_public_path__: string;
// eslint-disable-next-line
__webpack_public_path__ = (window as any).__PUBLIC_PATH as string;
// eslint-disable-next-line
export default __webpack_public_path__;
