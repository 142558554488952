import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { observable, computed, action } from 'mobx';
import { Toast } from '@casstime/bricks';

import { IResponseResult } from '../interfaces/IResponseResult';
import { constant, api } from '../config';
import { storage } from '../utils';
import { getHost } from '../utils/tool';
import { JSONParse } from '../utils/catch-exception';
// import { clearAllCookie } from '../utils/tool';
import { IUserInfo } from '../interfaces/IUserInfo';
import { IRole } from '../app/app.model';
import store from './index';
import { fetchUserInfoByAccessToken } from '../config/api';

// eslint-disable-next-line no-undef
// const isDev: boolean = process.env.NODE_ENV === 'development';
const noOneAccountUrl = ['dev.xiaomayz.com', 'localhost'];

/**
 * 管理鉴权相关业务，包含登陆注册，用户权限等
 */
export default class AuthStore {
  httpInstance: AxiosInstance;

  @observable token: string = storage.get(constant.LOCAL_JWT_TOKEN) || '';

  @observable userInfo: IUserInfo = storage.get(constant.LOCAL_USER_INFO) || {};

  /** 平台id */
  clientId = encodeURIComponent('xiaomayz');

  /** 平台密钥 */
  clientSecret = encodeURIComponent('lKSkKCquCIXcF3G7');

  /** 范围 */
  scope = encodeURIComponent('all');

  /** 平台登录账号 */
  @observable account = '';

  /** 一账通，重定向state */
  @observable redirectState?: string;

  /** 一账通，重定向uri */
  @observable redirectUri?: string;

  /** 一账通，重定向code */
  @observable redirectCode?: string;

  /** 新注册账户，成功后进入登录页面 */
  @observable isRegistryLogin = false;

  /** 新注册账户名称 */
  @observable registryName = '';

  /** 新注册账户密码 */
  @observable registryPassword = '';

  /** 登出操作，是否是小马处理的 */
  @observable isXmLogout = false;

  /** 登录操作，走小马登录流程，不走一账通 */
  @observable isXmLogin: boolean = noOneAccountUrl.includes(getHost()) || false;

  /** 走小马登录的权限码 */
  @observable permissionsList: string[] = [];

  constructor(httpInstance: AxiosInstance) {
    this.httpInstance = httpInstance;
    this.interceptRequest();
    this.interceptResponse();
    this.validateToken();
  }

  /** 小马平台操作，退出 */
  @action
  handleLogoutXm() {
    this.isXmLogout = true;
  }

  /** 设置，平台登录账号 */
  @action
  setAccount(account: string) {
    this.account = account;
  }

  /** 设置一账通，重定向state */
  @action
  updateRedirectState(state: string, uri: string) {
    this.redirectState = state;
    this.redirectUri = uri;
  }

  /** 设置新注册的账号名称 */
  @action
  setLoginType(isRegistryLogin: boolean) {
    this.isRegistryLogin = isRegistryLogin;
  }

  /** 设置新注册的账号名称 */
  @action
  setRegistryName(name: string) {
    this.registryName = name;
  }

  /** 设置新注册的账号名称 */
  @action
  setRegistryPassword(password: string) {
    this.registryPassword = password;
  }

  /**
   * 检查token，如果有token，并且过期的话，执行一次登出逻辑
   */
  validateToken() {
    if (!this.token) {
      return;
    }

    if (this.isTokenExpired()) {
      this.logout();
    }
  }

  @computed get isAuthenticated() {
    return !!this.token;
  }

  /**
   * 判断是否为超级管理员
   */
  @computed get isSuperAdmin() {
    const roles = this.userInfo.roles || [];
    const SUPER_ADMIN = 1001;
    return roles.some((role: IRole) => role.roleId === SUPER_ADMIN);
  }

  /**
   * 登陆
   */
  @action
  loginByPassword = async (username: string, password: string) => {
    this.interceptRequest();
    try {
      if (this.isXmLogin) {
        /** 走小马登录流程 */
        const userName = encodeURIComponent(username);
        const passWord = encodeURIComponent(password);
        const grantType = encodeURIComponent('password');
        const { data: res } = await this.httpInstance.post(
          `${api.userXMPasswordLogin}?grant_type=${grantType}&username=${userName}&password=${passWord}&client_id=${this.clientId}&client_secret=${this.clientSecret}&scope=${this.scope}`,
        );
        if (res && res.access_token) {
          this.setToken(res.access_token);
          this.setAccount(res.account);
          storage.set('ACCOUNT_NAME', res.account);
          this.handleUserInfoByAccessToken();
        }
        return res;
      }
      const { data: res } = await this.httpInstance.get(
        `${api.userPasswordLogin}?username=${encodeURIComponent(
          username,
        )}&password=${encodeURIComponent(
          password,
        )}&client_id=ssoagent&client_secret=123456&redirect_uri=${this.redirectUri}`,
      );
      return res;
    } catch (err) {
      console.warn('登陆失败', err);
    }
  };

  /** 设置登录成功后，获得的code */
  @action
  setRedirectCode(code: string) {
    this.redirectCode = code;
  }

  async loginByVerificationCode(cellphone: string, code: string) {
    try {
      if (this.isXmLogin) {
        /** 走小马登录流程 */
        const mobile = encodeURIComponent(cellphone);
        const verificationCode = encodeURIComponent(code);
        const grantType = encodeURIComponent('mobile');
        const { data: res } = await this.httpInstance.post(
          `${api.userXMVerificationLogin}?grant_type=${grantType}&mobile=${mobile}&verificationCode=${verificationCode}&client_id=${this.clientId}&client_secret=${this.clientSecret}&scope=${this.scope}`,
        );
        if (res && res.access_token) {
          this.setToken(res.access_token);
          this.setAccount(res.account);
          storage.set('ACCOUNT_NAME', res.account);
          this.handleUserInfoByAccessToken();
        }
        return res;
      }
      const { data: res } = await this.httpInstance.post(
        `${api.userVerificationLogin}?mobile=${encodeURIComponent(
          cellphone,
        )}&verificationCode=${encodeURIComponent(
          code,
        )}&client_id=ssoagent&client_secret=123456&redirect_uri=${this.redirectUri}`,
      );
      return res;
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * todo: any -> interface
   * 注册
   */
  @action
  async register(form: any) {
    const { data: res } = await this.httpInstance.post(api.userRegister, form);
    const userInfo = res.data;
    const token = res.jwtToken;
    this.setToken(token);
    this.setUserInfo(userInfo);
    return res;
  }

  /**
   *
   * @param userInfo 登录成功后，拿到的用户信息
   */
  @action
  setUserInfo(userInfo: IUserInfo) {
    this.userInfo = { ...userInfo };
    storage.set(constant.LOCAL_USER_INFO, userInfo);
    store.globalStore.setUserType(userInfo);
  }

  @action
  setToken(token: string) {
    this.token = token;
    storage.set(constant.LOCAL_JWT_TOKEN, token);
  }

  /**
   * 登出操作，清空鉴权信息
   */
  @action
  logout() {
    // clearAllCookie();
    storage.remove(constant.LOCAL_JWT_TOKEN);
    storage.remove(constant.LOCAL_USER_INFO);
    storage.remove('MENU_URL_LIST');
    storage.remove('ACCOUNT_NAME');
    storage.remove('COMPANY_ADDRESS_SELECTED_LEN');
    this.token = '';
    this.userInfo = {};
  }

  isTokenExpired() {
    try {
      const [, encodedPayload] = this.token.split('.');
      if (!encodedPayload) {
        return false;
      }
      const decodedPayloadStr = window.atob(encodedPayload);
      const payload = JSON.parse(decodedPayloadStr);
      const SEC = 1000;
      const expiredAt = parseInt(payload.exp, 10) * SEC;
      // 20秒误差防错
      return Date.now() > expiredAt - 20 * SEC;
    } catch (err) {
      console.error('解析jwt出错', err);
      return false;
    }
  }

  /** 正常获取用户信息时 */
  handleUserInfoSuccess = (data: any) => {
    if (data) {
      this.setUserInfo(data);
    }
    window.location.hash = '#/';
  };

  /** 处理获取用户信息异常的交互 */
  handleUserInfoError = (info: any) => {
    Toast.error(info.statusMsg || '登录失败！', 2000);
    const timer = setTimeout(() => {
      clearTimeout(timer);
      this.logout();
      window.location.hash = '#/login';
    }, 2100);
  };

  /** 走小马登录流程需要  处理获取用户信息后的相关操作 */
  handleUserInfoByAccessToken = (): void => {
    fetchUserInfoByAccessToken().then((info) => {
      if (info && info.statusCode === 200) {
        const data = info.data || {};
        this.permissionsList = data.permissions || [];
        this.handleUserInfoSuccess(info.data);
      } else {
        this.handleUserInfoError(info);
      }
    });
  };

  // 拦截response
  interceptResponse() {
    this.httpInstance.interceptors.response.use(
      (response: AxiosResponse<IResponseResult<any>>) => {
        // isDev && console.log('【返回数据】: ', response.data, '\n');
        if (typeof response.data === 'string') {
          response.data = JSONParse(response.data, {}) || {};
        }
        let message = '';
        if (response.data && response.data.statusCode === 200) {
          return response;
        }
        if (response.data && response.data.code === 400) {
          // 400：服务器返回错误
          const text = (response.data && response.data.msg) || '服务器异常! 请稍后再试...';
          Toast.error(text, 2000);
          this.logout();
          const timer = setTimeout(() => {
            clearTimeout(timer);
            // 状态码为400： 走小马主动退出
            this.handleLogoutXm();
            window.location.hash = '#/logout';
          }, 2100);
        } else if (response.data && response.data.statusCode === 403) {
          // 状态码 403：无权限  跳无权限页面
          window.location.hash = '#/component403';
        } else if (
          // 201: 未登陆  402：单端登陆踢出
          response.data &&
          [201, 402].includes(response.data.statusCode)
        ) {
          if (response.data.statusCode === 201) {
            message = '登录已过期，请重新登录！';

            // 表示登录已过期
            message = '登录已过期，请重新登录！';
            this.logout();
            const timer = setTimeout(() => {
              clearTimeout(timer);
              // 状态码为201 走小马主动退出
              this.handleLogoutXm();
              window.location.hash = '#/logout';
            }, 500);
          } else {
            message = response.data.statusMsg;

            const timer = setTimeout(() => {
              clearTimeout(timer);
              this.logout();
              // 状态码为402 走小马主动退出
              this.handleLogoutXm();
              window.location.hash = '#/logout';
              // 表示登录已过期
            }, 2100);
          }
          Toast.warn(message, 2000);

          return Promise.reject({ message });
        } else if (response.data) {
          return response;
        }
        message =
          (response.data && response.data.statusMsg) ||
          response.data.msg ||
          '服务器异常，请稍后重试！';
        Toast.error(message, 2000);
        return Promise.reject({ message });
      },
      (error) => {
        const err = (error && error.response && error.response.data) || '';
        const message = (err && (err.message || err.msg)) || '服务器异常! 请稍后再试...';
        if (err.code !== 410) {
          Toast.error(message, 2000);
        }
        if (err.code === 410) {
          window.location.hash = '#/loginError';
        }
        return Promise.reject({ message });
      },
    );
  }

  // 拦截request
  interceptRequest() {
    this.httpInstance.interceptors.request.use((config: AxiosRequestConfig) => {
      config.headers.common.platform = 'XMYZ_PC';
      if (this.token) {
        config.headers.common.Authorization = `Bearer ${this.token}`;
      }
      // eslint-disable-next-line no-undef
      // if (isDev) {
      //   console.log('【请求url】:', location.origin + config.url, '\n');
      //   if (config.method === 'post' || config.method === 'put') {
      //     console.log('【上送数据】:', config.data, '\n');
      //   }
      // }
      return config;
    });
  }
}
