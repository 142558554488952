import React from 'react';
import { withRouter } from 'react-router';
import { observer } from 'mobx-react';
import { RouteConfigComponentProps } from 'react-router-config';
import { Toast } from '@casstime/bricks';

import XMModal from '@/shared/components/XMModal';
import { http, api } from '../../../config';
import AuthStore from '../../../stores/AuthStore';
// import CustomizeModal from '../../../components/modal';
import shipperStore from '../../account_information/stores';
import staffStore from '../stores';
import receiverStore from '../../receiver/stores';
import GlobalStore from '../../../stores/GlobalStore';
import SettingStore from '../../account_information/stores/AccountStore';

interface IInviteMessage {
  companyId: string;
  companyName: string;
  inviteId: string;
  // invitedRole: number;
  invitedRole: [];
  isAccept: number;
}

interface IRejectedMessage {
  consumer: string; // 邀请记录中 员工的手机号
  inviteId: string; // 邀请记录的id
  msgType: number;
  data: {
    // invitedRole: number; // 此处是number类型，其他地方是string类型。
    invitedRole: []; // 此处是number类型，其他地方是string类型。
    companyId: string;
    companyName: string; // 发起邀请的企业名称
    invitedCellphone: string; // 被邀请者的手机号
    invitedNickName: string; // 被邀请者的昵称
    isAccept: number; // 1表示接受了 2表示拒绝了
  };
}

interface IUnboundMessage {
  consumer: string;
  data: {
    companyName: string;
    invitedNickName: string;
  };
  inviteId: string;
  msgType: number;
}

interface IProps extends RouteConfigComponentProps {
  authStore: AuthStore;
  settingStore: SettingStore;
  globalStore: GlobalStore;
}

interface IState {
  inviteMessages: IInviteMessage[]; // 员工收到的 企业发出邀请 的消息
  rejectedMessages: IRejectedMessage[]; // 企业收到的 员工拒绝邀请 的消息
  unboundMessages: IUnboundMessage[]; // 企业 | 员工 解绑的消息
}

@observer
class InviteMessageConfirm extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      inviteMessages: [],
      rejectedMessages: [],
      unboundMessages: [],
    };
  }

  componentDidMount(): void {
    this.pullMessage();
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    const { location } = this.props;
    if (prevProps.location.pathname !== location.pathname) {
      this.pullMessage();
    }
  }

  pullMessage = () => {
    const { authStore } = this.props;
    // 只有登陆用户才拉取消息，否则会导致鉴权出错，出现登陆已过期弹窗
    if (authStore && authStore.isAuthenticated) {
      this.handleManagerPull();
      this.handleUserPull();
    }
  };

  // 企业超级管理员 拉取消息
  handleManagerPull = () => {
    const { location, authStore } = this.props;
    if (['/kunming/useraddress/quickinput', '/login'].includes(location.pathname)) {
      return;
    }
    const { userInfo = {} } = authStore || {};
    http
      .post(api.settingInviteMsgPull, {
        cellphone: userInfo.cellphone,
        msgType: 1,
      })
      .then((res) => {
        if (Array.isArray(res.data)) {
          this.setState({
            rejectedMessages: res.data.filter((rejectedMessage: IRejectedMessage) => {
              return rejectedMessage && rejectedMessage.data && rejectedMessage.data.isAccept === 2;
            }),
          });
        }
      });
    http
      .post(api.settingInviteMsgPull, {
        cellphone: userInfo.cellphone,
        msgType: 2,
      })
      .then((res) => {
        this.setUnBandMsg(res);
      });
  };

  /** 设置未绑定数据 */
  setUnBandMsg = (res: any) => {
    const { unboundMessages } = this.state;
    if (Array.isArray(res.data)) {
      this.setState({
        unboundMessages: [...unboundMessages, ...res.data],
      });
    }
  };

  // 普通员工 | 普通管理员 拉取消息
  handleUserPull = () => {
    const { location, authStore } = this.props;
    if (['/kunming/useraddress/quickinput', '/login'].includes(location.pathname)) {
      return;
    }
    const { userInfo = {} } = authStore || {};

    http
      .post(api.settingInvitedFind, {
        invitedCellphone: userInfo.cellphone,
        isRead: 0,
      })
      .then((res) => {
        if (Array.isArray(res.data)) {
          // eslint-disable-next-line no-debugger
          this.setState({
            inviteMessages: res.data.filter((item: IInviteMessage) => item.isAccept === 0),
          });
        }
      });
    http
      .post(api.settingInviteMsgPull, {
        cellphone: userInfo.cellphone,
        msgType: 3,
      })
      .then((res) => {
        this.setUnBandMsg(res);
      });
  };

  /**
   * 员工 接受 | 拒绝 邀请的流程：
   */

  // 接受邀请
  handleAcceptInvite = (inviteMessage: IInviteMessage) => {
    this.handleSubmitInvite(inviteMessage, 1);
  };

  // 拒绝邀请
  handleRejectInvite = (inviteMessage: IInviteMessage) => {
    this.handleSubmitInvite(inviteMessage, 2);
  };

  /** 拒绝邀请关闭 */
  handleClose = () => {
    this.setState({
      inviteMessages: [],
    });
  };

  // 提交 接受 | 拒绝的 操作
  handleSubmitInvite = (inviteMessage: IInviteMessage, isAccept: 1 | 2) => {
    http
      .post(api.settingInviteReceive, {
        inviteId: inviteMessage.inviteId,
        companyId: inviteMessage.companyId,
        invitedRole: inviteMessage.invitedRole,
        isAccept,
      })
      .then((res) => {
        if (res.statusCode === 200) {
          const msg: string = isAccept === 1 ? '已接受邀请' : '已拒绝邀请';
          Toast.success(msg, 2000);
          this.handleCancelInvite(inviteMessage.inviteId); // 如果操作成功，则关闭该邀请的弹窗
        } else {
          Toast.error(res.statusMsg || '操作失败', 2000);
        }
        // 如果本次操作是拒绝邀请,则结束该流程。
        if (isAccept === 2) return;
        // 如果是接受邀请，执行相应的更新操作，清空列表中的其他邀请记录
        this.handleRelationshipChange();
        this.setState({ inviteMessages: [] });
      });
  };

  // 关闭 邀请 通知的弹窗
  handleCancelInvite = (inviteId: string) => {
    this.setState((state) => {
      return {
        inviteMessages: state.inviteMessages.filter(
          (inviteMessage) => inviteMessage.inviteId !== inviteId,
        ),
      };
    });
    this.userDetail();
    this.companyDetail();
  };

  // 关闭拒绝接受邀请的消息
  handleCloseRejectedMessage = (inviteId: string) => {
    this.setState((state) => {
      return {
        rejectedMessages: state.rejectedMessages.filter(
          (rejectedMessage) => rejectedMessage.inviteId !== inviteId,
        ),
      };
    });
  };

  // 关闭解绑的消息
  handleCloseUnboundMessage = (inviteId: string) => {
    this.setState((state) => {
      return {
        unboundMessages: state.unboundMessages.filter(
          (unboundMessage) => unboundMessage.inviteId !== inviteId,
        ),
      };
    });
    this.handleRelationshipChange();
  };

  // 当员工接受了邀请，邀请关系发生了变化，此时更新 settingStore 中的 user、 company，以及appStore中的 userInfo
  handleRelationshipChange = () => {
    const { settingStore, authStore, globalStore } = this.props;
    const { getAllShippers } = shipperStore.allShipperStore;
    const { getReceiverInfo } = receiverStore.receiverStore;
    const { fetchBankCardList } = shipperStore.bankCardStore;
    const account = authStore.userInfo.account || '';
    this.userDetail();
    this.companyDetail().then(() => {
      if (!globalStore.isAdminUser) {
        /** 个人用户重新获取我的绑定列表 */
        settingStore.fetchBindList(account);
      }
    });
    getAllShippers(); // 获取发货地址
    getReceiverInfo(); // 获取收货地址
    fetchBankCardList(); // 获取银行卡信息
  };

  userDetail = () => {
    // type 'unbind'-指被解绑了
    const { settingStore, authStore, globalStore } = this.props;
    const isAdmin = globalStore.isAdminUser;
    http.get(api.settingUserDetail).then((res) => {
      const { getStaffInfo } = staffStore.staffStore;
      if (res.data) {
        settingStore.initUser(res.data);
        // 在邀请关系发生变化 更新appStore的 userInfo信息（相应字段）
        authStore.setUserInfo(res.data);
        globalStore.setUserType(res.data);
        if (isAdmin) {
          getStaffInfo({
            // 管理员用户获取员工列表
            companyId: res.data.companyId,
            pageQo: {
              pageNo: 1,
              pageSize: 10,
            },
          });
        } else {
          // 普通用户重置我的绑定
          settingStore.updateBindCompanyList([]);
        }
      }
    });
  };

  companyDetail = () => {
    const { settingStore } = this.props;
    const requestMethod = http.get(api.settingCompanyDetail);
    requestMethod.then((res) => {
      if (res.data) {
        settingStore.initCompany(res.data);
      }
    });
    return requestMethod;
  };

  render() {
    const { inviteMessages, rejectedMessages, unboundMessages } = this.state;
    const { globalStore } = this.props;
    const { isAdminUser, isSuperUser } = globalStore;

    return (
      <>
        {unboundMessages.map((unboundMessage: IUnboundMessage) => {
          return (
            <XMModal
              visible
              key={unboundMessage.inviteId}
              modalWidth={100}
              title="解绑通知"
              confirmText="我知道啦"
              onClose={() => this.handleCloseUnboundMessage(unboundMessage.inviteId)}
              onConfirm={() => this.handleCloseUnboundMessage(unboundMessage.inviteId)}
              isFooter
            >
              {isAdminUser || isSuperUser
                ? (unboundMessage.data && unboundMessage.data.invitedNickName) || ''
                : (unboundMessage.data && unboundMessage.data.companyName) || ''}
              与你解除绑定，请知晓!
            </XMModal>
          );
        })}
        {rejectedMessages.map((rejectedMessage: IRejectedMessage) => {
          return (
            <XMModal
              key={rejectedMessage.inviteId}
              visible
              title="邀请回复"
              modalWidth={460}
              onClose={() => this.handleCloseRejectedMessage(rejectedMessage.inviteId)}
              onConfirm={() => this.handleCloseRejectedMessage(rejectedMessage.inviteId)}
              confirmText="我知道了"
              isFooter
            >
              <div>
                手机号
                {(rejectedMessage.data && rejectedMessage.data.invitedCellphone) || ''}
                的用户不接受你的邀请，
                <p>请知晓!</p>
              </div>
            </XMModal>
          );
        })}
        {inviteMessages.map((inviteMessage: IInviteMessage, index: number) => {
          return (
            <XMModal
              key={inviteMessage.inviteId}
              visible={index === 0}
              title="组织邀请"
              modalWidth={460}
              onConfirm={() => this.handleAcceptInvite(inviteMessage)}
              onCancel={() => this.handleRejectInvite(inviteMessage)}
              onClose={this.handleClose}
              confirmText="接受邀请"
              cancelText="拒绝邀请"
              isFooter
            >
              <div>
                {inviteMessage.companyName}
                邀请你加入他的组织，成为他的一员，你接受邀请后可以解除绑定，请知晓！
              </div>
            </XMModal>
          );
        })}
      </>
    );
  }
}

export default withRouter(InviteMessageConfirm);
