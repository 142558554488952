import { action, observable } from 'mobx';

import { IMenu, IAuthBtn } from '../interfaces/IMenu';
import { IUserInfo } from '../interfaces/IUserInfo';
import { fetchMenuList } from '../config/api';
import { handleFlatTreeData, getButtonAuth } from '../utils/tool';
import { storage, catchException } from '../utils';
import { constant } from '../config';

const whiteMenuUrlList = [
  '#/login',
  '#/',
  '#/offline_order/edit',
  '#/offline_order/create',
  '#/ks_order/edit',
  '#/ks_order/add',
  '#/announcements/details',
  '#/route_query',
];

class MenuStore {
  @observable userInfo: IUserInfo = storage.get(constant.LOCAL_USER_INFO) || {};

  @observable userName: string = storage.get('ACCOUNT_NAME') || '';

  /** 用户权限菜单 */
  @observable menus: IMenu[] = [];

  /** 用户按钮权限集合 */
  @observable authBtn: IAuthBtn[] = [];

  /**
   * 用户权限菜单,hash 集合
   */
  @observable menuUrlList: string[] = whiteMenuUrlList;

  /** 获取菜单list */
  @action
  async fetchMenuList() {
    const name = this.userInfo.account || this.userName;
    if ((this.userInfo && this.userInfo.account) || this.userName) {
      // const requestMethod = fetchMenuList(name, 'XMYZ_PC');
      const res = await fetchMenuList(name, 'XMYZ_PC');
      if (res.data && res.data.length) {
        this.authBtn = getButtonAuth([...res.data]) || [];
        this.menus = [...res.data];
      } else {
        /**
         * 菜单列表接口返回空
         * 跳转至用户无权限访问页 （按钮（返回登陆））
         *
         */
        window.location.hash = '#/component403';
      }
      this.menuUrlList = whiteMenuUrlList.concat(handleFlatTreeData(this.menus) as string[]);
      localStorage.setItem('MENU_URL_LIST', catchException.JSONStringify(this.menuUrlList, '[]'));
      // requestMethod.then(res => {
      //   if (res.data && res.data.length) {
      //     this.authBtn = getButtonAuth([...res.data]) || [];
      //     this.menus = [...res.data];
      //   } else {
      //     /**
      //      * 菜单列表接口返回空
      //      * 跳转至用户无权限访问页 （按钮（返回登陆））
      //      *
      //      */
      //     window.location.hash = '#/component403';
      //   }
      //   this.menuUrlList = whiteMenuUrlList.concat(
      //     handleFlatTreeData(this.menus) as string[]
      //   );
      //   localStorage.setItem('MENU_URL_LIST', JSON.stringify(this.menuUrlList));
      // });
      // return requestMethod;
    }
  }

  /**
   * 设置用户权限菜单
   * @param menus
   */
  @action
  setMenu(menus: IMenu[]) {
    this.menus = menus || [];
  }
}

export default MenuStore;
