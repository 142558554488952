import qs from 'query-string';
import { Toast } from '@casstime/bricks';
import { v1 as uuidv1 } from 'uuid';
import { IAuthBtn } from '../interfaces/IMenu';
import { JSONParse, JSONStringify } from './catch-exception';

/** 拼接入参字段 */
export const createLocationSearch = (query: object) => {
  return qs.stringify(query);
};

/** 讲入参字符串,根据key解析成obj对象 */
export const parseLocationSearch = (search: string) => {
  return qs.parse(search);
};

export const getDate = (timeStamp?: number | string) => {
  const time = timeStamp ? new Date(timeStamp) : new Date();
  const y = time.getFullYear();
  const m = `0${time.getMonth() + 1}`.slice(-2);
  const d = `0${time.getDate()}`.slice(-2);
  return `${y}-${m}-${d} ${time.toTimeString().substr(0, 8)}`;
};

export const getDateStr = (timeStamp?: number | string) => {
  const time = timeStamp ? new Date(timeStamp) : null;
  if (time === null) {
    return '-';
  }
  const y = time.getFullYear();
  const m = `0${time.getMonth() + 1}`.slice(-2);
  const d = `0${time.getDate()}`.slice(-2);
  return `${y}-${m}-${d} ${time.toTimeString().substr(0, 8)}`;
};

/** 转换list序号数值 */
export const setListIndexInit = (index: number, pageNum: number, pageSize: number) => {
  const indexNo = (pageNum - 1) * pageSize + index + 1;
  return indexNo < 10 ? `0${indexNo}` : `${indexNo}`;
};

/** 特殊表格，偶数编码计算 */
export const setListIndex = (index: number, pageNum: number, pageSize: number) => {
  let result = '01';
  const order = index / 2 + 1;
  const isEvenIndex = index % 2 === 0;
  if (pageNum === 1) {
    if (isEvenIndex && order < 10) {
      result = `0${order}`;
    } else if (isEvenIndex) {
      result = `${order}`;
    }
  } else if (pageNum > 1) {
    if (isEvenIndex) {
      result = `${(pageNum - 1) * pageSize + order}`;
    }
  }
  return result;
};

/** 往数组中，插入空对象 */
export const insertItemToList = (list: any[], obj: any) => {
  let result: any[] = [];
  if (list && list.length) {
    for (let i = list.length; i > 0; i--) {
      list.splice(i, 0, obj);
    }
    result = list;
  }
  return result;
};

export const getUuid = () => {
  // 生成uuid
  const reg = /-/g;
  const pwd = uuidv1().replace(reg, '');
  return pwd;
};

/** 座扁平化处理，树形结构数据 */
export const handleFlatTreeData = (data: any[]) => {
  if (data.length === 0) return [];
  const result: string[] = [];
  data.forEach((first: any) => {
    if (first.menuUrl) {
      result.push(first.menuUrl);
    }
    if (first.children.length > 0) {
      first.children.forEach((second: any) => {
        if (second.menuUrl) {
          result.push(second.menuUrl);
        }
      });
    }
  });
  return result;
};

/** 导出文件 */
export const handleExportFiles = (files: any, type = 'filename=') => {
  let fileName = '';
  const header = files.headers;
  try {
    let contentDsposition = header['content-disposition'];
    if (!contentDsposition) {
      Toast.error('当前没有数据', 2000);
      return;
    }
    contentDsposition = contentDsposition.split(';')[1];
    fileName = decodeURIComponent(contentDsposition.replace(type, ''));
  } catch (e) {
    console.error(e);
  }
  const resData = new Blob([files.data]);
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // for ie
    window.navigator.msSaveOrOpenBlob(resData, fileName);
  } else {
    const url = window.URL.createObjectURL(resData);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  }
};

/** 对象数组去重 */
export const objectArrayDedupe = (data: any[], filterKey: string) => {
  if (!data.length) return [];
  const obj: { [key: string]: any } = {};
  for (const item of data) {
    obj[item[filterKey]] = item;
  }
  return Object.values(obj) || [];
};

/** 获取所有按钮权限 */
const allAuthBtn: IAuthBtn[] = [];
export const getButtonAuth = (menus: any[]) => {
  menus.forEach((item: any) => {
    if (item.menuType === 2 && item.perms) {
      allAuthBtn.push({ menuName: item.menuName, perms: item.perms });
    }
    if (item.children.length) {
      getButtonAuth(item.children);
    }
  });
  return objectArrayDedupe(allAuthBtn, 'perms');
};

/** 随机生成uuid */
export const uuid = () => {
  const url = URL.createObjectURL(new Blob());
  const id = url.toString();
  return id.substr(id.lastIndexOf('/') + 1);
};

export const delCookie = () => {
  const keys = document.cookie.match(/[^ =;]+(?==)/g);
  if (keys) {
    for (let i = keys.length; i--; ) {
      document.cookie = `${keys[i]}=0;path=/;expires=${new Date(0).toUTCString()}`; // 清除当前域名下的,例如：m.ratingdog.cn
      document.cookie = `${keys[i]}=0;path=/;domain=${document.domain};expires=${new Date(
        0,
      ).toUTCString()}`; // 清除当前域名下的，例如 .m.ratingdog.cn
      document.cookie = `${keys[i]}=0;path=/;domain=dev.xiaomayz.com;expires=${new Date(
        0,
      ).toUTCString()}`; // 清除一级域名下的或指定的，例如 .ratingdog.cn
    }
  }
};

// 根据小马代发origin，判断拼接的一账通origin
export const getAccountSysOrigin = () => {
  const ORIGIN = window.location.origin;
  let result = '';
  if (ORIGIN.includes('//localhost') || ORIGIN.includes('//dev')) {
    /** 电商dev 环境已挂  dev 先对应test */
    // result = 'https://passport-xmwldev.casstime.com';
    result = 'https://passport-test.casstime.com';
  } else if (ORIGIN.includes('//test')) {
    result = 'https://passport-test.casstime.com';
  } else if (ORIGIN.includes('//demo')) {
    result = 'https://passport-demo.casstime.com';
  } else if (ORIGIN.includes('//backup')) {
    result = 'https://passport-backup.casstime.com';
  } else {
    result = 'https://passport.casstime.com';
  }
  return result;
};

// 清除所有cookie函数

export const clearAllCookie = async () => {
  const ID = 'xiaomayz-a6174143-3aee-419a-b068-c7f503b5f209';
  const date = new Date();
  date.setTime(date.getTime() - 10000);
  // eslint-disable-next-line no-useless-escape
  const keys = document.cookie.match(/[^ =;]+(?=\=)/g);
  if (keys) {
    for (let i = keys.length; i--; ) {
      if (keys[i] !== ID) {
        document.cookie = `${keys[i]}=0; expire=${date.toUTCString()}; path=/`;
      }
    }
  }
};

/** 获取 域名 无端口号 */
export const getHost = () => {
  const { host } = window.location;
  const hostList = host.split(':');
  return hostList[0];
};

/** 深拷贝 对对象 数组深拷贝，无null function */
export const deepCopy = (originObj: object) => {
  const targetObj = JSONParse(JSONStringify(originObj), {});
  return targetObj;
};

/**
 * 判断某两个时间是否属于同一天
 * @param startDate 第一个时间
 * @param endDate  第二个时间
 */
export const isSameDay = (startDate: string, endDate: string) => {
  return new Date(startDate).toDateString() === new Date(endDate).toDateString();
};

export const removeStrEnter = (str: string) => {
  const newStr = str.replace(/[\r\n]/g, '');
  return newStr;
};
