import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import raf from 'raf';
import { HashRouter } from 'react-router-dom';

import { routes } from '../config';
import stores from '../stores';
import AuthGuard from './AuthGuard';
import InviteMessageConfirm from '../pages/staff_manage/components/InviteMessageConfirm';

import '@casstime/bricks/dist/bricks.production.css';
import '../styles/index.scss';

const { globalStore, authStore, settingStore } = stores;

raf.polyfill();

function App() {
  return (
    <HashRouter>
      <Provider {...stores}>
        <>
          <AuthGuard routes={routes} />
          <InviteMessageConfirm
            globalStore={globalStore}
            authStore={authStore}
            settingStore={settingStore}
          />
        </>
      </Provider>
    </HashRouter>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));
