// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Component } from 'react';
import { RouteConfig } from 'react-router-config';
import { Switch, Route, Redirect } from 'react-router-dom';
import { inject } from 'mobx-react';
import AuthStore from '../stores/AuthStore';

interface IAuthGuardProps {
  routes: RouteConfig[];
  authStore?: AuthStore;
}

@inject('authStore')
export default class AuthGuard extends Component<IAuthGuardProps> {
  render() {
    const { authStore, routes } = this.props;
    return (
      <Switch>
        {routes.map((route: RouteConfig, i: number) => {
          const C = route.component as any;
          return (
            <Route key={route.key || i} path={route.path} exact={route.exact} strict={route.strict}>
              {!route.authGuard || (authStore && authStore.isAuthenticated) ? (
                <C {...this.props} route={route} />
              ) : (
                <Redirect to={{ pathname: '/login' }} />
              )}
            </Route>
          );
        })}
      </Switch>
    );
  }
}
