import { ICascaderOption, utils } from '@casstime/bricks';

import axios from './http';
import { IPlatform } from '../interfaces/IPlatform';
import { IMenu } from '../interfaces/IMenu';
import { IUserInfo } from '../interfaces/IUserInfo';
import { IFetchToken } from '../interfaces/IFetchToken';
import { IResponseResult } from '../interfaces/IResponseResult';

/** 站点模块，站点范围选择穿梭机，数据结构 */
export interface IAddressRange extends utils.ITree {
  key: string;
  title: string;
  geoType: string;
  parentGeoId: string;
  children?: IAddressRange[];
}

/** 用户登录后，根据account获取菜单列表 */
export const fetchMenuList = (
  account: string,
  platform: IPlatform,
): Promise<IResponseResult<IMenu[]>> => {
  return axios.get(`/kunming/sys/user/menu/list?account=${account}&platform=${platform}`);
};

/** 第二步： auth流程 */
export const fetchUserInfo = (code: string): Promise<IFetchToken> => {
  return axios.post(
    `/auth/oauth/token?client_id=xiaomayz&client_secret=lKSkKCquCIXcF3G7&grant_type=ssoCode&ssoCode=${code}&scope=all`,
  );
};

/** 第三步： 通过access_token换取用户信息 */

export const fetchUserInfoByAccessToken = (): Promise<IResponseResult<IUserInfo>> => {
  return axios.get('/kunming/auth/success/user/info');
};

/** 获取行政区域（按需加载） */
export const getAddressList = (parentId: string): Promise<IResponseResult<ICascaderOption[]>> => {
  return axios.get(`/kunming/region/list?parentId=${parentId}`).then((res) => res);
};

/** 获取行政区域（一次性获得全部） */
export const getGeoList = (): IAddressRange[] => {
  return require('../assets/mockData/geoList.json');
};

const prefix = '/kunming';

const API = {
  /**
   * 通用接口
   */
  commonRegionList: `${prefix}/region/list`, // 获取城市列表
  commonBankList: `${prefix}/bank/list`, // 获取银行列表
  commonRegisterCheck: `${prefix}/invite/user/check`, // 检查手机号是否已经注册
  getUploadToken: `${prefix}/waybill/oss/sts_token?sessionName=xiaoma`, // 获取上传token
  getLogisticsSchedule: `${prefix}/waybill/logistics/cutofftime/list`, // 获取物流公司截单时间表

  /**
   * login logout register
   */
  userSendVerificationCode: `${prefix}/auth/send/verification_code`, // 发送短信验证码  identifyType = APPLY_LOGIN:登录; APPLY_REGISTER:注册; APPLY_FIND_PASSWORD:找回密码
  userSendVerificationCode_sys: '/auth/userauthorize/send/verification_code', // 发送短信验证码  identifyType = APPLY_LOGIN:登录; APPLY_REGISTER:注册; APPLY_FIND_PASSWORD:找回密码
  userPasswordLogin: '/auth/userauthorize/generatecode', // 密码登录 post 方式，需要将参数拼接到uri中
  userVerificationLogin: '/auth/userauthorize/generatecode', // 验证码登录 post 方式，需要将参数拼接到uri中
  userCheckVerification: `${prefix}/auth/find_password/check/verification_code`, // 找回密码时校验验证码是否准确
  userUpdatePassword: `${prefix}/auth/update/password`, // 修改密码 put 方式，需要将参数拼接到uri中
  userRegister: `${prefix}/auth/user/register`, // 用户注册
  userLogout: `${prefix}/auth/logout`, // 退出登录
  userFindSecretByCellphone: `${prefix}/auth/validate/cellphone`, // 根据手机号验证是否允许找回密码和注册

  /**
   * 企业设置接口
   */

  settingUserDetail: `${prefix}/sys/user/userinfo`, // 获取账号信息
  settingUserUpdate: `${prefix}/sys/user/userinfo/update`, // 更新账号信息
  settingUserIsUserNameExist: `${prefix}/sys/user/userinfo/isUserNameExist`, // 检查登录名是否存在
  settingCompanyDetail: `${prefix}/company/companyinfo`, // 获取账号所在的企业信息
  settingCompanyCreate: `${prefix}/company/companyinfo/create`, // 创建企业信息
  settingCompanyUpdate: `${prefix}/company/companyinfo/update`, // 更新企业信息
  settingAllBounds: `${prefix}/company/boundCompany`, // 获取员工所绑定的企业列表

  settingAllShippers: `${prefix}/useraddress/page`, // 获取所有的发货地址
  settingShipperCreate: `${prefix}/useraddress/create`, // 新增 发货地址
  settingShipperUpdate: `${prefix}/useraddress/update`, // 修改 发货地址
  settingShipperDelete: `${prefix}/useraddress/delete`, // 删除 发货地址
  settingShipperDefault: `${prefix}/useraddress/setdefaultSenderAddress`, // 设置默认收货地址

  settingInviteCheck: `${prefix}/invite/check`, // 检查手机号是否被邀请
  settingInviteSend: `${prefix}/invite/send`, // 发送邀请关系

  settingInvitedFind: `${prefix}/invite/find`, // 员工查询企业发起的邀请通知
  settingInviteMsgPull: `${prefix}/invite/msg/pull`, // 查询关系变更通知 包括：员工拒绝了邀请 | 企业解绑员工 | 员工解绑企业
  settingInviteReceive: `${prefix}/invite/receive`, // 1接受 | 0拒绝 邀请
  settingInviteUnbind: `${prefix}/invite/unbind`, // 解绑

  /** 收货人 */

  receiverAddAddress: `${prefix}/useraddress/create`, // Method: post(新增地址信息)
  receiverGetAddressByShipperId: `${prefix}/useraddress/get`, // Method: get(根据shipperId获取地址信息)
  receiverDeleteAddress: `${prefix}/useraddress/delete`, // Method: delete(删除地址信息)
  receiverGetQuickAddress: `${prefix}/useraddress/getQuickInputAddress`, // Method: get(获取快速编辑地址信息的连接)
  receiverSearchAddress: `${prefix}/useraddress/page`, // Method: post(查询用户地址信息,模糊查询地址信息)
  receiverSubmitQuickAddress: `${prefix}/useraddress/quickinput`, // Method: post(获取快速编辑地址信息提交接口，根据url的参数可以判断)
  receiverSetDefaultAddress: `${prefix}/useraddress/setdefaultSenderAddress`, // Method: get(设置默认发货方地址信息)
  receiverUpdateAddress: `${prefix}/useraddress/update`, // Method: put(修改发货方地址信息)

  /** 小马登录流程 login logout register */
  userXMPasswordLogin: '/auth/oauth/token', // 密码登录 post 方式，需要将参数拼接到uri中
  userXMVerificationLogin: '/auth/oauth/token', // 验证码登录 post 方式，需要将参数拼接到uri中
};

export default API;
