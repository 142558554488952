import React, { ReactNode } from 'react';

import Icon from '@/assets/images/login.svg';

import styles from './index.module.scss';

interface ILodingProps {
  /** loading */
  loading?: boolean;
  /** 子元素 */
  children?: ReactNode;
  className?: string;
}

function Loading(props: ILodingProps) {
  const { children, loading, className } = props;
  return (
    <div className={styles.loadingContainer}>
      {children}
      {loading && (
        <div className={styles.loadingShade}>
          <div>
            <img src={Icon} className={className} />
            <span />
            加载中...
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(Loading);
