import { action, observable } from 'mobx';
import storage from '../utils/storage';
import { IUserInfo, IRole } from '../interfaces/IUserInfo';

function isAdminUser(userInfo: IUserInfo) {
  // roleId： '1000'，代表超级管理员，'1001'，代表管理员，其他代表普通用户
  const roles = (userInfo && userInfo.roles) || [];
  const userRoles: number[] = [];
  if (roles.length) {
    roles.forEach((role: IRole) => {
      userRoles.push(role.roleId);
    });
  }
  return userRoles.some((item) => [1000, 1001].includes(item)) || false;
}

/**
 * 是否小马超级管理员
 * @param userInfo
 */
function isSuperUser(userInfo: IUserInfo) {
  const roles = (userInfo && userInfo.roles) || [];
  let userRoles: number[] = [];
  if (roles.length) {
    userRoles = roles.map((role: IRole) => {
      return role.roleId;
    });
  }
  return userRoles.includes(1009);
}
/**
 * 全局变量store
 */
class GlobalStore {
  /**
   * body 高
   */
  @observable domHeight: number;

  /**
   * body 宽
   */
  @observable domWidth: number;

  @observable isPersonUser: boolean; // 是否普通用户

  @observable isCompanyUser: boolean; // 是否企业用户

  @observable isAdminUser: boolean; // 是否管理员用户

  @observable isSuperUser: boolean; // 是否小马超级管理员

  constructor() {
    const userInfo = storage.get('userInfo') || {};
    this.domHeight = 0;
    this.domWidth = 0;
    this.isPersonUser = !isAdminUser(userInfo);
    this.isCompanyUser = !!(userInfo && userInfo.companyId) || false;
    this.isAdminUser = isAdminUser(userInfo);
    this.isSuperUser = isSuperUser(userInfo);
  }

  /** * 设置视口宽度 */
  @action setDomWidth(w: number) {
    this.domWidth = w;
  }

  /** * 设置 视口高度 */
  @action setDomHeight(w: number) {
    this.domHeight = w;
  }

  /** * 设置 用户类型 */
  @action setUserType(userInfo: IUserInfo) {
    this.isPersonUser = !(userInfo && userInfo.companyId) || false;
    this.isCompanyUser = !!(userInfo && userInfo.companyId) || false;
    this.isAdminUser = isAdminUser(userInfo);
    this.isSuperUser = isSuperUser(userInfo);
  }
}

export default GlobalStore;
