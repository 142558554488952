import { observable, action } from 'mobx';

import { ISearchAddressResponse, IReceiverListReq } from '../interfaces';
import { api, http } from '../../../config';

class ReceiverStore {
  @observable receiverListRes: ISearchAddressResponse = {
    total: 0,
    pageNum: 1,
    pageSize: 10,
    pages: 0,
    list: [],
  };

  @observable receiverListReq: IReceiverListReq = {
    addressType: 'addressee_address',
    fullAddress: '',
    customerName: '',
    pageNum: 1,
    pageSize: 10,
  };

  @observable loading = false;

  @action
  getReceiverInfo = (data?: IReceiverListReq) => {
    // 列表信息
    const req = { ...this.receiverListReq, ...data };
    this.loading = true;
    http
      .post(api.receiverSearchAddress, req)
      .then((res) => {
        if (res.statusCode === 200 && res.data) {
          this.setReceiverListRes(res.data);
        }
      })
      .finally(() => {
        this.loading = false;
      });
  };

  /** 设置请求信息 */
  @action setReceiverListRes = (val: ISearchAddressResponse) => {
    this.receiverListRes = val;
  };
}

export default ReceiverStore;
