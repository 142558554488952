import Loadable from 'react-loadable';
import { RouteConfig } from 'react-router-config';
import Loading from '../components/Loading';

const routes: RouteConfig[] = [
  {
    path: '/no-sidebar',
    authGuard: false,
    component: Loadable({
      loader: () => import('../layouts/NoSidebarLayout'),
      loading: Loading,
    }),
  },
  {
    path: '/login',
    exact: true,
    authGuard: false,
    component: Loadable({
      loader: () => import('../app/components/Login'),
      loading: Loading,
    }),
  },
  {
    path: '/loginError',
    exact: true,
    authGuard: false,
    component: Loadable({
      loader: () => import('../components/error/LoginError'),
      loading: Loading,
    }),
  },
  {
    path: '/loginSso',
    exact: true,
    authGuard: false,
    component: Loadable({
      loader: () => import('../components/LoginSso'),
      loading: Loading,
    }),
  },
  {
    path: '/logout',
    exact: true,
    authGuard: false,
    component: Loadable({
      loader: () => import('../components/LogoutSso'),
      loading: Loading,
    }),
  },
  {
    path: '/component403',
    component: Loadable({
      loader: () => import('../components/error/Component403'),
      loading: Loading,
    }),
  },
  {
    path: '/component404',
    component: Loadable({
      loader: () => import('../components/error/Component404'),
      loading: Loading,
    }),
  },
  {
    path: '/component500',
    component: Loadable({
      loader: () => import('../components/error/Component500'),
      loading: Loading,
    }),
  },
  {
    path: '/kunming/useraddress/quickinput',
    exact: true,
    authGuard: false,
    component: Loadable({
      loader: () => import('../pages/receiver/components/ReceiverCollect'),
      loading: Loading,
    }),
  },
  {
    path: '/',
    authGuard: true,
    component: Loadable({
      loader: () => import('../layouts/DefaultLayout'),
      loading: Loading,
    }),
    routes: [
      {
        path: '/',
        exact: true,
        component: Loadable({
          loader: () => import('../pages/order_manage'),
          loading: Loading,
        }),
      },
      {
        path: '/route_query',
        exact: true,
        authGuard: false,
        component: Loadable({
          loader: () => import('../pages/route_query/index'),
          loading: Loading,
        }),
      },
      {
        path: '/ks_order',
        exact: true,
        authGuard: true,
        component: Loadable({
          loader: () => import('../pages/order_manage'),
          loading: Loading,
        }),
      },
      {
        path: '/offline_order',
        exact: true,
        authGuard: true,
        component: Loadable({
          loader: () => import('../pages/order_manage'),
          loading: Loading,
        }),
      },
      {
        path: '/offline_order/create',
        exact: true,
        authGuard: true,
        component: Loadable({
          loader: () => import('../pages/create_waybill/index'),
          loading: Loading,
        }),
      },
      {
        path: '/ks_order/edit',
        exact: true,
        authGuard: true,
        component: Loadable({
          loader: () => import('../pages/create_waybill/index'),
          loading: Loading,
        }),
      },
      {
        path: '/offline_order/edit',
        exact: true,
        authGuard: true,
        component: Loadable({
          loader: () => import('../pages/create_waybill/index'),
          loading: Loading,
        }),
      },
      {
        path: '/receiver',
        exact: true,
        authGuard: true,
        component: Loadable({
          loader: () => import('../pages/receiver'),
          loading: Loading,
        }),
      },
      {
        path: '/setting/company_setting',
        exact: true,
        authGuard: true,
        component: Loadable({
          loader: () => import('../pages/account_information'),
          loading: Loading,
        }),
      },
      {
        path: '/setting/staff_manage',
        exact: true,
        authGuard: true,
        component: Loadable({
          loader: () => import('../pages/staff_manage'),
          loading: Loading,
        }),
      },
      {
        path: '/feedback',
        exact: true,
        authGuard: true,
        component: Loadable({
          loader: () => import('../pages/feedback'),
          loading: Loading,
        }),
      },
      {
        path: '/announcements',
        exact: true,
        authGuard: true,
        component: Loadable({
          loader: () => import('../pages/announcements'),
          loading: Loading,
        }),
      },
      {
        path: '/announcements/details',
        exact: true,
        authGuard: true,
        component: Loadable({
          loader: () => import('../pages/announcements/details'),
          loading: Loading,
        }),
      },
    ],
  },
];

export default routes;
