import axios from '../../../config/http';

import { IResponseResult } from '../../../interfaces/IResponseResult';
import { IStaffReq } from '../interfaces/IStaffReq';
import { IStaffRepo } from '../interfaces/IPagingRepo';
import { IUnbindStaffReq, IUnbindStaffRepo } from '../interfaces/IUnbindStaff';
// songyuhao 2020.0720
// import { IPlatform } from '../../../interfaces/IPlatform';
import { IRoleList } from '../interfaces/IRoleList';
import { IAccountInfo } from '../interfaces/IAccountInfo';
import { IAccountInfoReq } from '../interfaces/IAccountInfoReq';

/** 获取员工列表 */
export const getStaffList = (data: IStaffReq): Promise<IResponseResult<IStaffRepo>> => {
  return axios.post('/kunming/invite/user/findPage', data).then((res) => res);
};

/** 删除用户(接触绑定) */
export const deleteStaff = (data: IUnbindStaffReq): Promise<IResponseResult<IUnbindStaffRepo>> => {
  return axios.post('/kunming/invite/unbind', data).then((res) => res);
};
/** 根据平台类型获取角色列表 */
export const fetchRoleList = (): Promise<IResponseResult<IRoleList[]>> => {
  // return axios.get(`/kunming/sys/role/listByPlatforms/${platforms}`);
  return axios.get(`/kunming/sys/role/listByInvited`);
};
/** 获取账户信息 */
export const fetchAccountInfo = (account: string): Promise<IResponseResult<IAccountInfo>> => {
  return axios.get(`/kunming/sys/user/userinfo/${account}`);
};
/** 修改账号信息 */
export const updateAccount = (data: IAccountInfoReq): Promise<IResponseResult<IAccountInfo>> => {
  return axios.put('/kunming/sys/user/userinfo/update', data);
};
