import { action, observable } from 'mobx';
import { Toast } from '@casstime/bricks';
// import { IUser } from '../interfaces/IUser';
import { fetchBindList } from '../api';
import { IUser, IRole, ICompany, ICompanyInfon } from '../interfaces';

import { http, api } from '../../../config';

class AccountStore {
  @observable user: IUser = {};

  @observable company: ICompany = {};

  @observable bindCompanyList: ICompanyInfon[] = [];

  @action
  initUser(user: IUser) {
    // 根据roles 列表 计算 该用户是否是超级管理员 1000 超级管理员 1001 普通管理员 1002 受邀请员工
    this.user = {
      ...user,
      isManager: (user.roles || []).some((role: IRole) => role.roleId === 1000),
    };
  }

  @action
  initCompany(company: ICompany) {
    this.company = company;
  }

  /** 获取用户信息 */
  @action
  featchUserDetail = () => {
    http.get(api.settingUserDetail).then((res) => {
      if (res.data) {
        this.initUser(res.data);
        // this.props.settingStore.initUser(res.data);
        // // 在打开该页面 或者 邀请关系发生变化 更新appStore的 userInfo信息（相应字段）
        // this.props.authStore!.setUserInfo(res.data);
        // this.props.globalStore!.setUserType(res.data);
      }
    });
  };

  /** 获取公司信息 */
  @action
  featchCompanyDetail = () => {
    const requestMethod = http.get(api.settingCompanyDetail);
    requestMethod.then((res) => {
      if (res.data) {
        this.initCompany(res.data);
      }
    });
    return requestMethod;
  };

  @action
  updateBindCompanyList(data: ICompanyInfon[]) {
    this.bindCompanyList = data;
  }

  @action
  fetchBindList(account: string) {
    fetchBindList(account)
      .then((res) => {
        if (res.data && res.statusCode === 200) {
          const list = Array.isArray(res.data) ? res.data : [res.data];
          this.updateBindCompanyList(list);
        } else {
          this.updateBindCompanyList([]);
        }
      })
      .catch((e) => {
        Toast.error(e.msg || '获取失败', 2000);
      });
  }
}

export default AccountStore;
