import { action, observable } from 'mobx';

import { IShipper } from '../interfaces';
import { http, api } from '../../../config';
import { insertItemToList } from '../../../utils/tool';

class AllShipperStore {
  @observable shippers: IShipper[] = [];

  @observable loading = false;

  @action
  getAllShippers = () => {
    this.loading = true;
    // 获取发货地址信息
    http
      .post(api.settingAllShippers, {
        addressType: 'sender_address',
        pageNum: 1,
        pageSize: 100,
      })
      .then((res) => {
        if (res.data && res.statusCode === 200) {
          const info = res.data;
          res.data.list =
            info && info.list && info.list.length ? insertItemToList(res.data.list, {}) : [];
          this.setShippers(res.data.list);
        }
      })
      .finally(() => {
        this.loading = false;
      });
  };

  /** 设置发货地址信息 */
  @action setShippers = (val: IShipper[]) => {
    this.shippers = val;
  };
}

export default AllShipperStore;
