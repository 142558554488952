import { action, observable } from 'mobx';

import { IBankCardInfo, IAddBankCard } from '../interfaces/IBankCard';
import { insertItemToList } from '../../../utils/tool';
import { fetchBankCardList, deleteBankCard, defaultBankCard, addBankCard } from '../api';

/** 获取银行卡信息 */
class AllBankCardStore {
  @observable bankCardList: IBankCardInfo[] = [];

  @observable loading = false;

  @action
  fetchBankCardList = () => {
    this.loading = true;
    fetchBankCardList()
      .then((res) => {
        if (res.data && res.data.length && res.statusCode === 200) {
          let info = res.data;
          info = insertItemToList(info, {});
          this.updateBankCard(info);
        }
      })
      .finally(() => {
        this.loading = false;
      });
  };

  /** 更新银行卡信息 */
  @action
  updateBankCard = (val: IBankCardInfo[]) => {
    this.bankCardList = val;
  };

  /** 删除银行卡 */
  @action
  deleteBankCard = (params: { id: number }) => {
    return deleteBankCard(params);
  };

  /** 默认银行卡 */
  @action
  defaultBankCard = (params: { id: number }) => {
    return defaultBankCard(params);
  };

  /** 新增银行卡 */
  @action
  addBankCard = (params: IAddBankCard) => {
    return addBankCard(params);
  };
}

export default AllBankCardStore;
