import { observable, action } from 'mobx';

import { IStaffRepo } from '../interfaces/IPagingRepo';
import { IStaffReq } from '../interfaces/IStaffReq';
import { getStaffList } from '../api';

class StaffStore {
  @observable staffRepo: IStaffRepo = {
    total: 0,
    pageSize: 10,
    pageNum: 1,
    pages: 0,
    list: [],
  };

  @observable loading = false;

  @action
  getStaffInfo = (params: IStaffReq) => {
    // 获取员工信息
    this.loading = true;
    getStaffList(params)
      .then((res) => {
        if (res.statusCode === 200 && res.data) {
          this.setStaffRepo(res.data);
        }
      })
      .finally(() => {
        this.loading = false;
      });
  };

  /** 设置员工请求信息 */
  @action setStaffRepo = (val: IStaffRepo) => {
    this.staffRepo = val;
  };
}

export default StaffStore;
