import MenuStore from './MenuStore';
import AuthStore from './AuthStore';
import GlobalStore from './GlobalStore';
import AppStore from '../app/app.store';
import { httpInstance } from '../config/http';
import SettingStore from '../pages/account_information/stores/AccountStore';

export default {
  appStore: new AppStore(),
  authStore: new AuthStore(httpInstance),
  settingStore: new SettingStore(),
  menuStore: new MenuStore(),
  globalStore: new GlobalStore(),
};
