import axios from '../../../config/http';

import { IResponseResult } from '../../../interfaces/IResponseResult';
import { ICompanyInfon } from '../interfaces';
import { IBankCardInfo, IAddBankCard } from '../interfaces/IBankCard';

/** 获取我的绑定列表 */
export const fetchBindList = (account: string): Promise<IResponseResult<ICompanyInfon>> => {
  return axios.get(`/kunming/company/boundCompany/${account}`).then((res) => res);
};

/** 获取银行卡列表 */
export const fetchBankCardList = (): Promise<IResponseResult<IBankCardInfo[]>> => {
  return axios.get(`/kunming/bank/get?source=${'PC'}`);
};

/** 保存银行卡 */
export const addBankCard = (params: IAddBankCard): Promise<IResponseResult<true>> => {
  return axios.post('/kunming/bank/add', params);
};

/** 选中默认 */
export const defaultBankCard = (params: { id: number }): Promise<IResponseResult<true>> => {
  return axios.post(`/kunming/bank/default?id=${params.id}`, params);
};

/** 删除银行卡 */
export const deleteBankCard = (params: { id: number }): Promise<IResponseResult<true>> => {
  return axios.delete(`/kunming/bank/delete?id=${params.id}`);
};
