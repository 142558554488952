import React, { ReactNode } from 'react';
import { Button, Modal } from '@casstime/bricks';
import classNames from 'classnames';

import Loading from '../Loading/index';

import styles from './index.module.scss';

interface IModalProps {
  /** 弹窗宽度 */
  modalWidth?: number;
  /** 对话框是否可见 */
  visible: boolean;
  /** 点击确定回调 */
  onConfirm?: () => void;
  /** 右上角叉或取消按钮的回调 */
  onCancel?: () => void;
  /** 点击右上角叉的回调 */
  onClose?: () => void;
  /** 点击遮罩得回调 */
  onBackdropClick?: () => void;
  /** 对话框标题 */
  title?: string;
  /** 对话框提示语 */
  tips?: string;
  /** 确认按钮文案 */
  confirmText?: string;
  /** 取消按钮文案 */
  cancelText?: string;
  /** Header部分 */
  isHeader?: boolean;
  /** Footer部分 */
  isFooter?: boolean;
  /** 子元素 */
  children?: ReactNode;
  /** 是否可拖动 */
  isDraggable?: boolean;
  /** 确认按钮禁用状态 */
  isDisabledConfirmBtn?: boolean;
  /** 加载状态 */
  loading?: boolean;
  className?: string;
}
const XMModal: React.FunctionComponent<IModalProps> = (props) => {
  const {
    visible,
    onConfirm,
    onCancel,
    onClose,
    onBackdropClick,
    title,
    tips,
    confirmText,
    cancelText,
    isHeader,
    children,
    isDraggable,
    isDisabledConfirmBtn,
    modalWidth,
    loading,
    isFooter,
    className,
  } = props;

  return (
    <Modal
      isDraggable={isDraggable}
      showBackdrop
      visible={visible}
      onBackdropClick={onBackdropClick}
      className={classNames(styles.XMModalWrapper, className)}
      style={{ width: `${modalWidth || 420}px` }}
    >
      {(isHeader || onClose || title) && (
        <Modal.Header onClose={onClose || onCancel}>
          <span>{title}</span>
        </Modal.Header>
      )}
      <Loading loading={loading}>
        <Modal.Content
          className={classNames('commonScrollBar', {
            [styles.hideModalContent]: !(tips || children),
          })}
        >
          {(tips && <div className={styles.contentTips}>{tips}</div>) || children}
        </Modal.Content>
        {isFooter && (
          <Modal.Footer>
            {onConfirm && (
              <Button
                disabled={isDisabledConfirmBtn}
                round
                large
                className={classNames(styles.modalBtn, styles.confirmBtn)}
                onClick={() => onConfirm()}
              >
                {confirmText || '确定'}
              </Button>
            )}
            {onCancel && (
              <Button
                round
                large
                className={classNames(styles.modalBtn, styles.cancelBtn)}
                onClick={onCancel}
                outline
              >
                {cancelText || '取消'}
              </Button>
            )}
          </Modal.Footer>
        )}
      </Loading>
    </Modal>
  );
};

export default XMModal;
