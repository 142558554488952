export default {
  LOCAL_USER_INFO: 'userInfo',
  LOCAL_JWT_TOKEN: 'BAI_LONG_MA_TOKEN',
  ADDRESS_TITLES: ['省份', '城市', '县区', '街道'],
  SITE_OPERATE_ATTR: [
    {
      label: '直营站点',
      checked: false,
      disabled: false,
    },
    {
      label: '加盟站点',
      checked: false,
      disabled: false,
    },
  ],
  SITE_FUNCTION_ATTR: [
    {
      label: '揽收',
      checked: false,
      disabled: false,
    },
    {
      label: '中转',
      checked: false,
      disabled: false,
    },
    {
      label: '分拨',
      checked: false,
      disabled: false,
    },
    {
      label: '派送',
      checked: false,
      disabled: false,
    },
  ],
};
