import React from 'react';

const Loading = () => (
  <div className="loading-container">
    <span />
    加载中...
  </div>
);

export default Loading;
