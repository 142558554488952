import { action, observable } from 'mobx';
import { IRole, UserInfo } from './app.model';
import { storage } from '../utils';
import { constant } from '../config';
import { IUserInfo } from '../interfaces/IUserInfo';
import { clearHttpCacheToken } from '../config/http';

import { IMenu } from '../interfaces/IMenu';

class AppStore {
  /** 登录用户信息 */
  @observable userInfo: UserInfo | IUserInfo = new UserInfo();

  /** 目录列表  */
  @observable menuList: IMenu[] = [];

  /**
   * handleLogin 用于设置 userInfo信息，和 配置路由。在三个地方会被调用
   * 1、用户登录后
   * 2、用户注册后
   * 3、用户重新打开页面，判断token没有失效时，会从localStorage读取保存的userInfo
   */
  @action
  handleLogin(userInfo: UserInfo) {
    this.userInfo = userInfo;
    storage.set(constant.LOCAL_USER_INFO, userInfo);
    if (userInfo.jwt_token) {
      storage.set(constant.LOCAL_JWT_TOKEN, userInfo.jwt_token);
    }
  }

  /**
   * 当企业设置中 邀请 与 解绑关系发生变化后
   * 会重新请求 /company/userinfo接口，使用返回值，更新 appStore中的 userinfo
   */

  @action
  updateUserInfo(updated: { nickName: string; type: number; isCassUser: number; roles: IRole[] }) {
    this.userInfo.nickName = updated.nickName;
    this.userInfo.type = updated.type;
    this.userInfo.isCassUser = updated.isCassUser;
    this.userInfo.roles = updated.roles || [];
    this.userInfo.isManager = (updated.roles || []).some((role: IRole) => role.roleId === 1000);
    storage.set(constant.LOCAL_USER_INFO, this.userInfo);
  }

  @action
  handleLogout() {
    this.userInfo = new UserInfo();
    this.menuList = [];
    storage.remove(constant.LOCAL_USER_INFO);
    storage.remove(constant.LOCAL_JWT_TOKEN);
    clearHttpCacheToken();
  }
}

export default AppStore;
