/**
 * 该文件主要提供格式化函数，函数命名统一已format开头
 *
 */

/**
 *
 * @param value 需要处理的字段
 * @param prefix 前缀
 * @param suffix 后缀
 */
// eslint-disable-next-line import/prefer-default-export
export const formatNull = (value: string, prefix = '', suffix = '') => {
  if (!value) {
    return '';
  }
  return prefix + value + suffix;
};
